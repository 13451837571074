:root {
  --blue: #207bea;
  --black: #000;
  --grey-dark: #666666;
  --grey: #e0e0e0;
}

html{
  background-color: rgb(243 244 246 / 1);
}


.navbar__option {
  padding: 0;
  cursor: pointer;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right:3rem;
}
.navbar__option_span{
  display: flex;
  flex-direction: row;
  padding:.25rem .75rem;
  font-size: 1.25rem/* 30px */;
  line-height: 1.75rem/* 36px */;
  font-weight: 600;
}
.navbar__option_span:hover {
  border-bottom: var(--grey) 2px solid;
}

.navbar__option_span.active {
  border-bottom: white 2px solid;

}

.navbar__option.mobile {
  color: black
}
.navbar__option_span.active.mobile {
  border-bottom: black 2px solid;
}

.navbar__option svg {
  margin-right: 8px;
}
.flip-x {
  transform: scaleX(-1);
}

/* txn toggle */
.txn_type_toggle{
  background: var(--grey);
  border: var(--grey) 1px solid;
  border-radius: 6px;
  display: inline-flex;
  height: fit-content;
  color: var(--grey-dark);
}

.txn_type_toggle__option{
  padding: .125rem .5rem;
  border-radius: 6px;
  font-weight: 700;
  cursor: pointer;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.txn_type_toggle__option.active{
  background: white;
  color: black;
  cursor: normal;
}

/* TAILWIND OVERWRITES */
.border-1{
  border-width: 1px;
}